var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-drawer',{attrs:{"zIndex":1000,"title":_vm.dialog.type === 'add'? 'Add New Amenity' : 'Edit Amenity',"width":'650px',"maskClosable":false,"visible":_vm.dialog.show,"drawerStyle":{height: 'calc(100% - 50px)',overflow: 'auto'}},on:{"close":_vm.onClose}},[_c('bh-loading',{attrs:{"show":_vm.loading}}),_c('a-form-model',{ref:"newAmenity",staticClass:"h-full dF fC jSB",attrs:{"model":_vm.newAmenity}},[_c('div',{staticClass:"f1"},[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-model-item',{attrs:{"required":"","label":"Amenity Label","prop":"name","rules":_vm.req('Please enter the amenity name')}},[_c('a-input',{attrs:{"placeholder":"Example: Soccer Field"},model:{value:(_vm.newAmenity.name),callback:function ($$v) {_vm.$set(_vm.newAmenity, "name", $$v)},expression:"newAmenity.name"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-model-item',{attrs:{"label":"Description"}},[_c('a-textarea',{attrs:{"rows":4,"placeholder":"Description of the Amenity","required":""},model:{value:(_vm.newAmenity.description),callback:function ($$v) {_vm.$set(_vm.newAmenity, "description", $$v)},expression:"newAmenity.description"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"xs":{span:24},"xl":{span:12}}},[_c('a-form-model-item',{attrs:{"label":"Featured Image","prop":"thumbnail"}},[_c('ImageBox',{attrs:{"type":2,"img":_vm.newAmenity.thumbnail},on:{"callback":_vm.selectedThumbnail}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"xs":{span:24},"xl":{span:12}}},[_c('a-form-model-item',{attrs:{"label":"Media","prop":"image"}},[_c('ImageBox',{attrs:{"type":2,"img":_vm.newAmenity.image},on:{"callback":_vm.selectedImg}})],1)],1)],1)],1),_c('div',{style:({
        position: 'absolute',
        left: 0,
        bottom:0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '10px 16px',
        background: '#fff',
        textAlign: 'right',
        })},[_c('div',{staticClass:"df jSB"},[_c('div',[(_vm.dialog.type === 'edit')?_c('a-button',{staticStyle:{"width":"120px","float":"left"},attrs:{"type":"danger","ghost":"","icon":"delete"},on:{"click":_vm.deleteAmenity}},[_vm._v("DELETE")]):_vm._e()],1),_c('div',[_c('a-button',{staticClass:"cancel-button",staticStyle:{"width":"120px"},style:({marginRight: '8px'}),on:{"click":_vm.onClose}},[_vm._v(" CANCEL ")]),_c('a-button',{staticStyle:{"width":"120px"},attrs:{"type":"primary"},on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.dialog.type === 'add'? 'CREATE' : 'UPDATE'))])],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }