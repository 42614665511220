<template>
    <div>

        <a-modal centered :visible="siteplanRulesModal" :confirmLoading="loadModal" :footer="null"  :width="650" :title="null" @cancel="closeRule" class="amenity-dialog" :class="loadModal? 'amenity-modal-loading' : ''" :bodyStyle="{padding:0}">
            <a-tabs v-model="activeTab">

                <a-tab-pane tab="Tab 2" key="1">
                    <!--rule 1-->
                    <div class="px-5 py-3 mt-3">
                        <h3>Same Home Model side by side?</h3>
                            <a-form>
                                <a-form-item label="How many times can the same Home Model reoccur side by side?">

                                    <a-select v-model="control.modelSide" style="width: 100%">
                                        <a-select-option :value="-1" >Does not Apply</a-select-option>
                                        <a-select-option v-for="i in 5" :value="i" :key="`modelsidebyside${i}`">
                                            {{i === 1? 'None' : `${i} in a row`}}
                                        </a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-form>
                    </div>
                    <div class="w-full px-3 pb-3 mt-3 dF jSB">
                        <div>
                            <!-- <a-button type="primary" @click="activeTab = (parseInt(activeTab) - 1) + ''"><a-icon type="arrow-left"/> Prev</a-button> -->
                        </div>

                        <div>
                        <a-button type="primary" @click="activeTab = (parseInt(activeTab) + 1) + ''">Next <a-icon type="arrow-right"  /></a-button>
                        </div>
                    </div>
                </a-tab-pane>
                <a-tab-pane tab="Tab 2" key="2">
                    <div class="px-5 py-3 mt-3">
                        <!--rule 2-->
                        <h3>Number of <strong>Home Models</strong> per 10 building?</h3>
                            <a-form>
                                <a-form-item label="How many of the same Home Models can be sited per a block of 10?">
                                    <a-select v-model="control.modelPer10" style="width: 100%">
                                        <a-select-option :value="-1" >Does not Apply</a-select-option>
                                        <a-select-option v-for="i in 5" :value="i" :key="`modelsidebyside${i}`">
                                            {{i === 1? 'Only one' : `${i} out of 10`}}
                                        </a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-form>
                    </div>
                    <div class="w-full px-3 pb-3 mt-3 dF jSB">
                        <div>
                            <a-button type="primary" @click="activeTab = (parseInt(activeTab) - 1) + ''"><a-icon type="arrow-left"/> Prev</a-button>
                        </div>

                        <div>
                            <a-button type="primary" @click="activeTab = (parseInt(activeTab) + 1) + ''">Next <a-icon type="arrow-right"  /></a-button>
                        </div>
                    </div>
                </a-tab-pane>
                <a-tab-pane tab="Tab 2" key="3">
                    <div class="px-5 py-3 mt-3">
                        <!--rule 3-->
                        <h3>Same Home Model Elevation side by side?</h3>
                        <a-form>
                            <a-form-item label="How many times can the same Home Model Elevation reoccur side by side?">
                                <a-select v-model="control.elevSide" style="width: 100%">
                                    <a-select-option :value="-1" >Does not Apply</a-select-option>
                                    <a-select-option v-for="i in 5" :value="i" :key="`modelsidebyside${i}`">
                                        {{i === 1? 'None' : `${i} in a row`}}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-form>
                    </div>
                    <div class="w-full px-3 pb-3 mt-3 dF jSB">
                        <div>
                            <a-button type="primary" @click="activeTab = (parseInt(activeTab) - 1) + ''"><a-icon type="arrow-left"/> Prev</a-button>
                        </div>

                        <div>
                            <a-button type="primary" @click="activeTab = (parseInt(activeTab) + 1) + ''">Next <a-icon type="arrow-right"  /></a-button>
                        </div>
                    </div>
                </a-tab-pane>
                <a-tab-pane tab="Tab 2" key="4">
                    <div class="px-5 py-3 mt-3">
                        <!--rule 4-->
                        <h3>Number of <strong>Home Model Elevations</strong> per 10 building?</h3>
                        <a-form>
                            <a-form-item label="How many of the same Home Model Elevations can be sited per a block of 10?">
                                <a-select v-model="control.elevPer10" style="width: 100%">
                                    <a-select-option :value="-1" >Does not Apply</a-select-option>
                                    <a-select-option v-for="i in 5" :value="i" :key="`modelsidebyside${i}`">
                                    {{i === 1? 'Only one' : `${i} out of 10`}}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-form>
                    </div>
                    <div class="w-full px-3 pb-3 mt-3 dF jSB">
                        <div>
                            <a-button type="primary" @click="activeTab = (parseInt(activeTab) - 1) + ''"><a-icon type="arrow-left"/> Prev</a-button>
                        </div>

                        <div>
                            <a-button type="primary" @click="activeTab = (parseInt(activeTab) + 1) + ''">Next <a-icon type="arrow-right"  /></a-button>
                        </div>
                    </div>
                </a-tab-pane>
                <a-tab-pane tab="Tab 2" key="5">
                    <div class="px-5 py-3 mt-3">
                        <!--rule 5-->
                        <h3>Same Home Model Elevation in a Streetscape (%)</h3>
                        <a-form>
                            <a-form-item label="What is the maximum percentage of occurrence of the same Home Model Elevation in a streetscape?">
                                <a-select v-model="control.elevStreetPercentage" style="width: 100%">
                                    <a-select-option :value="-1" >Does not Apply</a-select-option>
                                    <a-select-option v-for="i in 5" :value="i" :key="`modelsidebyside${i}`">
                                    {{i * 10}} %
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-form>
                    </div>
                    <div class="w-full px-3 pb-3 mt-3 dF jSB">
                        <div>
                            <a-button type="primary" @click="activeTab = (parseInt(activeTab) - 1) + ''"><a-icon type="arrow-left"/> Prev</a-button>
                        </div>

                        <div>
                            <a-button type="primary" @click="activeTab = (parseInt(activeTab) + 1) + ''">Next <a-icon type="arrow-right"  /></a-button>
                        </div>
                    </div>
                </a-tab-pane>
                <a-tab-pane tab="Tab 2" key="6">
                    <div class="px-5 py-3 mt-3">
                        <!--rule 3-->
                        <h3>Lot apart between identical elevations?</h3>
                        <a-form>
                            <a-form-item label="How many lots need to be between identical elevations?">
                                <a-select v-model="control.sameElevLotSpaces" style="width: 100%">
                                    <a-select-option :value="-1" >Does not Apply</a-select-option>
                                    <a-select-option v-for="i in 10" :value="i" :key="`modelsidebyside${i}`">
                                    {{i === 1? '1 Lot Space' : `${i} Lot Spaces`}}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-form>
                    </div>
                    <div class="w-full px-3 pb-3 mt-3 dF jSB">
                        <div>
                            <a-button type="primary" @click="activeTab = (parseInt(activeTab) - 1) + ''"><a-icon type="arrow-left"/> Prev</a-button>
                        </div>

                        <div>
                            <a-button type="primary" @click="activeTab = (parseInt(activeTab) + 1) + ''">Next <a-icon type="arrow-right"  /></a-button>
                        </div>
                    </div>
                </a-tab-pane>
                <a-tab-pane tab="Tab 2" key="7">
                    <div class="px-5 py-3 mt-3">
                        <!--rule 6-->
                        <h3>Can identical Elevations be across from each other?</h3>
                            <a-form>
                                <a-form-item >
                                    <a-select v-model="control.sameElevAcross" style="width: 100%">
                                        <a-select-option :value="1" >Yes</a-select-option>
                                        <a-select-option :value="0" >No</a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-form>
                    </div>
                    <div class="w-full px-3 pb-3 mt-3 dF jSB">
                        <div>
                            <a-button type="primary" @click="activeTab = (parseInt(activeTab) - 1) + ''"><a-icon type="arrow-left"/> Prev</a-button>
                        </div>

                        <div>
                            <a-button type="primary" @click="activeTab = (parseInt(activeTab) + 1) + ''">Next <a-icon type="arrow-right"  /></a-button>
                        </div>
                    </div>
                </a-tab-pane>
                <a-tab-pane tab="Tab 2" key="8">
                    <div class="px-5 py-3 mt-3">
                        <!--rule 7-->
                        <h3>Lot space between identical Brick package?</h3>
                            <a-form>
                                <a-form-item label="Identify the number of lot spaces required between identical architectural colour packages.">
                                    <a-select v-model="control.sameBrickLotSpaces" style="width: 100%">
                                        <a-select-option :value="-1" >Does not Apply</a-select-option>
                                        <a-select-option v-for="i in 10" :value="i" :key="`modelsidebyside${i}`">
                                        {{i === 1? '1 Lot Space' : `${i} Lot Spaces`}}
                                        </a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-form>
                    </div>
                    <div class="w-full px-3 pb-3 mt-3 dF jSB">
                        <div>
                            <a-button type="primary" @click="activeTab = (parseInt(activeTab) - 1) + ''"><a-icon type="arrow-left"/> Prev</a-button>
                        </div>

                        <div>
                            <a-button type="primary" @click="activeTab = (parseInt(activeTab) + 1) + ''">Next <a-icon type="arrow-right"  /></a-button>
                        </div>
                    </div>
                </a-tab-pane>
                <a-tab-pane tab="Tab 2" key="9">
                    <div class="px-5 py-3 mt-3">
                        <!--rule 9-->
                        <h3>Can identical Architectural Color Package be across from each other?</h3>
                            <a-form>
                                <a-form-item >
                                    <a-select v-model="control.sameBrickAcross" style="width: 100%">
                                        <a-select-option :value="1" >Yes</a-select-option>
                                        <a-select-option :value="0" >No</a-select-option>
                                        <a-select-option :value="-1" >Does not Apply</a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-form>
                    </div>
                    <div class="w-full px-3 pb-3 mt-3 dF jSB">
                        <div>
                            <a-button :disabled="loadModal" type="primary" @click="activeTab = (parseInt(activeTab) - 1) + ''"><a-icon type="arrow-left"/> Prev</a-button>
                        </div>

                        <div>
                            <a-button type="primary" :disabled="loadModal" @click="applyRules">Apply Rules <a-icon :type="loadModal? 'loading' : 'arrow-right'"  /></a-button>
                        </div>
                    </div>
                </a-tab-pane>




            </a-tabs>


        </a-modal>
        <a-drawer
            :zIndex="1000"
            title="Edit Architectural Rules"
            :width="'650px'"
            @close="onClose"
            :visible="siteplanRulesDrawer"
            :drawerStyle="{height: 'calc(100% - 50px)',overflow: 'auto'}"
            >
            <!-- <bh-loading :show="loading" /> -->
            <a-form  layout="vertical" class="h-full dF fC jSB">

                <div class="f1">
                    <a-row :gutter="16">
                        <h5>Same Home Model side by side?</h5>
                        <a-form-item label="How many times can the same Home Model reoccur side by side?">
                            <a-select v-model="control.modelSide" style="width: 100%">
                                <a-select-option :value="-1" >Does not Apply</a-select-option>
                                <a-select-option v-for="i in 5" :value="i" :key="`modelsidebyside${i}`">
                                    {{i === 1? 'None' : `${i} in a row`}}
                                </a-select-option>
                            </a-select>
                        </a-form-item>

                        <hr />

                        <h5>Number of <strong>Home Models</strong> per 10 building?</h5>
                        <a-form-item label="How many of the same Home Models can be sited per a block of 10?">
                            <a-select v-model="control.modelPer10" style="width: 100%">
                                <a-select-option :value="-1" >Does not Apply</a-select-option>
                                <a-select-option v-for="i in 5" :value="i" :key="`modelsidebyside${i}`">
                                    {{i === 1? 'Only one' : `${i} out of 10`}}
                                </a-select-option>
                            </a-select>
                        </a-form-item>

                        <hr />

                        <h5>Same Home Model Elevation side by side?</h5>
                        <a-form-item label="How many times can the same Home Model Elevation reoccur side by side?">
                            <a-select v-model="control.elevSide" style="width: 100%">
                                <a-select-option :value="-1" >Does not Apply</a-select-option>
                                <a-select-option v-for="i in 5" :value="i" :key="`modelsidebyside${i}`">
                                    {{i === 1? 'None' : `${i} in a row`}}
                                </a-select-option>
                            </a-select>
                        </a-form-item>

                        <hr />

                        <h5>Number of <strong>Home Model Elevations</strong> per 10 building?</h5>
                        <a-form-item label="How many of the same Home Model Elevations can be sited per a block of 10?">
                            <a-select v-model="control.elevPer10" style="width: 100%">
                                <a-select-option :value="-1" >Does not Apply</a-select-option>
                                <a-select-option v-for="i in 5" :value="i" :key="`modelsidebyside${i}`">
                                {{i === 1? 'Only one' : `${i} out of 10`}}
                                </a-select-option>
                            </a-select>
                        </a-form-item>

                        <hr />

                        <h5>Same Home Model Elevation in a Streetscape (%)</h5>
                        <a-form-item label="What is the maximum percentage of occurrence of the same Home Model Elevation in a streetscape?">
                            <a-select v-model="control.elevStreetPercentage" style="width: 100%">
                                <a-select-option :value="-1" >Does not Apply</a-select-option>
                                <a-select-option v-for="i in 5" :value="i" :key="`modelsidebyside${i}`">
                                {{i * 10}} %
                                </a-select-option>
                            </a-select>
                        </a-form-item>

                        <hr />

                        <h5>Lot apart between identical elevations?</h5>
                        <a-form-item label="How many lots need to be between identical elevations?">
                            <a-select v-model="control.sameElevLotSpaces" style="width: 100%">
                                <a-select-option :value="-1" >Does not Apply</a-select-option>
                                <a-select-option v-for="i in 10" :value="i" :key="`modelsidebyside${i}`">
                                {{i === 1? '1 Lot Space' : `${i} Lot Spaces`}}
                                </a-select-option>
                            </a-select>
                        </a-form-item>

                        <hr />

                        <h5>Can identical Elevations be across from each other?</h5>
                        <a-form-item >
                            <a-select v-model="control.sameElevAcross" style="width: 100%">
                                <a-select-option :value="1" >Yes</a-select-option>
                                <a-select-option :value="0" >No</a-select-option>
                            </a-select>
                        </a-form-item>

                        <hr />

                        <h5>Lot space between identical Brick package?</h5>
                        <a-form-item label="Identify the number of lot spaces required between identical architectural colour packages.">
                            <a-select v-model="control.sameBrickLotSpaces" style="width: 100%">
                                <a-select-option :value="-1" >Does not Apply</a-select-option>
                                <a-select-option v-for="i in 10" :value="i" :key="`modelsidebyside${i}`">
                                {{i === 1? '1 Lot Space' : `${i} Lot Spaces`}}
                                </a-select-option>
                            </a-select>
                        </a-form-item>

                        <hr />

                        <h5>Can identical Architectural Color Package be across from each other?</h5>
                        <a-form-item >
                            <a-select v-model="control.sameBrickAcross" style="width: 100%">
                                <a-select-option :value="1" >Yes</a-select-option>
                                <a-select-option :value="0" >No</a-select-option>
                                <a-select-option :value="-1" >Does not Apply</a-select-option>
                            </a-select>
                        </a-form-item>




                    </a-row>


                </div>

                <div
                :style="{
                    position: 'absolute',
                    left: 0,
                    bottom: 0,
                    width: '100%',
                    borderTop: '1px solid #e9e9e9',
                    padding: '10px 16px',
                    background: '#fff',
                    textAlign: 'right',
                }"
                >

                <div class="dF jSB">
                    <a-button :style="{marginRight: '8px'}" style="width:120px" class="cancel-button" @click="onClose">
                    CANCEL
                    </a-button>
                    <a-button  type="primary" style="width:120px" @click="updateRules">UPDATE</a-button>
                </div>


                </div>

            </a-form>

        </a-drawer>

    </div>

</template>

<script>
export default {
    data(){
        return {
            activeTab:'1',
            loadModal:false,
            control:{
                modelSide:1,
                modelPer10:1,
                sameElevLotSpaces:1,
                elevPer10:1,
                elevStreetPercentage:10, //10-50
                sameElevAcross:1,
                sameBrickLotSpaces:1,
                sameElevAndBrickLotSpaces:1,
                sameBrickAcross:0
            }

        }
    },
    watch:{
        siteplanRulesModal(val){
            if (!val) this.loadModal = false
            this.activeTab = '1'
            this.control = {
                modelSide:1,
                modelPer10:1,
                sameElevLotSpaces:1,
                elevPer10:1,
                elevStreetPercentage:10, //10-50
                sameElevAcross:1,
                sameBrickLotSpaces:1,
                sameElevAndBrickLotSpaces:1,
                sameBrickAcross:0
            }
        },
        siteplanRulesDrawer(val){
            if (val){
                let control = JSON.parse(JSON.stringify(this.$store.state.siteplan.rules))
                control.sameElevAcross = control.sameElevAcross? 1 : 0
                // control.sameBrickAcross = control.sameBrickAcross? 1 : 0
                this.control = control
                console.log('CONTROLLLL', this.control.id, this.control)
            } else {
                this.control = {
                modelSide:1,
                modelPer10:1,
                sameElevLotSpaces:1,
                elevPer10:1,
                elevStreetPercentage:10, //10-50
                sameElevAcross:1,
                sameBrickLotSpaces:1,
                sameElevAndBrickLotSpaces:1,
                sameBrickAcross:0
            }
            }
        }
    },
    computed:{
        siteplanRulesDrawer(){
            return this.$store.state.siteplan.siteplanRulesDrawer
        },
        siteplanRulesModal(){
            return this.$store.state.siteplan.siteplanRulesModal
        },
        siteplan(){
            return this.$store.state.siteplan.appData
        },

    },
    methods:{
        closeRule(){
            console.log('close rule ')
            this.$store.commit('CLOSE_SETTINGS')
        },
        updateRules(){
            console.log('upDATING RULES')
            let self = this
            this.loadModal = true
            this.$api.put(`/architectural-controls/${this.$store.state.instance.id}/${this.siteplan.id}/${this.control.id}`, this.control).then( ({data}) => {
                self.$store.commit('APPLY_RULES', data)
                self.$store.commit('CLOSE_SETTINGS')
            }).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					self.$message.error(self.$err(err))
				}
			})
        },
        onClose(){
            this.$store.commit('CLOSE_SETTINGS')
        },
        applyRules(){
            // console.log('THIS ROUTE', this.$route.meta.splashScreen)
            let isSetup = this.$route.meta.splashScreen


            let self = this
            this.loadModal = true
            this.$api.post(`/architectural-controls/${this.$store.state.instance.id}/${this.siteplan.id}`, this.control).then( ({data}) => {
                self.$store.commit('APPLY_RULES', data)
                self.$store.commit('CLOSE_SETTINGS')
                if (isSetup) self.$router.push('/')
            }).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					self.$message.error(self.$err(err))
				}
			})
        }
    }
}
</script>

<style>

</style>
