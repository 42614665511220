<template>
    <a-drawer
        :zIndex="1000"
        :title="dialog.type === 'add'? 'Add New Amenity' : 'Edit Amenity'"
        :width="'650px'"
        @close="onClose"
        :maskClosable="false"
        :visible="dialog.show"
        :drawerStyle="{height: 'calc(100% - 50px)',overflow: 'auto'}"
    >
    <bh-loading :show="loading" />
    <a-form-model  class="h-full dF fC jSB" ref="newAmenity" :model="newAmenity">
        <div class="f1">
            <a-row :gutter="16">
                <a-col :span="24">
                    <a-form-model-item required label="Amenity Label" prop="name" :rules="req('Please enter the amenity name')">
                        <a-input v-model="newAmenity.name" placeholder="Example: Soccer Field"></a-input>
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row :gutter="16">
                <a-col :span="24">
                    <a-form-model-item label="Description">
                        <a-textarea
                        :rows="4"
                        placeholder="Description of the Amenity"
                        required
                        v-model="newAmenity.description"
                        />
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row :gutter="16">
                <a-col :xs="{span:24}" :xl="{span:12}">
                    <a-form-model-item label="Featured Image" prop="thumbnail">
                        <ImageBox :type=2 :img="newAmenity.thumbnail" @callback="selectedThumbnail" />
                        <!-- <div class="dF">
                            <a-input class="mr-3" style="flex: 1;" :disabled="true" v-model="newAmenity.thumbnail"></a-input>
                            <a-button @click="$store.commit('MEDIA_SELECT',{callback:selectedThumbnail,type:'images'})" style="width: 120px; background-color:var(--light-gray)"><a-icon type="plus" />ADD IMAGE</a-button>
                        </div>
                        <div>Minimum upload file size: 1MB.</div>       -->
                    </a-form-model-item>


                </a-col>
            </a-row>
            <a-row :gutter="16">
                <a-col  :xs="{span:24}" :xl="{span:12}">
                    <a-form-model-item label="Media" prop="image" >
                        <ImageBox :type=2 :img="newAmenity.image" @callback="selectedImg" />
                        <!-- <div class="dF">
                            <a-input class="mr-3" style="flex: 1;" :disabled="true" v-model="newAmenity.image"></a-input>
                            <a-button @click="$store.commit('MEDIA_SELECT',{callback:selectedImg,type:'images'})" style="width:120px; background-color:var(--light-gray)"><a-icon type="plus" />ADD MEDIA</a-button>
                        </div>
                        <div>Minimum upload file size: 5MB.</div> -->
                    </a-form-model-item>

                </a-col>
            </a-row>
        </div>
        <div
            :style="{
            position: 'absolute',
            left: 0,
            bottom:0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            }"
        >
            <div class="df jSB">
                <div>
                    <a-button @click="deleteAmenity" v-if="dialog.type === 'edit'" style="width:120px; float:left" type="danger" ghost icon="delete">DELETE</a-button>
                </div>
                <div>
                    <a-button @click="onClose" :style="{marginRight: '8px'}" style="width:120px;" class="cancel-button">
                    CANCEL
                    </a-button>
                    <a-button @click="submit" style="width:120px" type="primary" >{{dialog.type === 'add'? 'CREATE' : 'UPDATE'}}</a-button>
                </div>
            </div>
        </div>
    </a-form-model>
    </a-drawer>

</template>

<script>
import bhLoading from 'bh-mod/components/common/Loading'
import mime from 'mime-types'
import SelectMedia from '@/components/siteplan/SelectMedia'
import ImageBox from '@/components/siteplan/ImageBox'

export default {
    components:{SelectMedia, bhLoading, ImageBox},
    data() {
        return {
            loading:false,
            fileType:mime.lookup({type:String, required:true}),
            newAmenity: {
                image:'',
                thumbnail:'',
                name:'',
                description:'',
                shapeId:'',
                shape:{},
                type:'info',
                siteplan:this.$store.state.siteplan.appData.id
            }
        }
    },
    watch: {
        dialog:{
            handler(val) {
                if (!val.show) {
                    this.loading = false
                    this.newAmenity = {
                        thumbnail:'',
                        image:'',
                        name:'',
                        description:'',
                        shapeId:'',
                        shape:{},
                        type:'info',
                        siteplan:this.$store.state.siteplan.appData.id
                    }
                }
                else if (val.type == 'add') {
                    this.newAmenity.shapeId = String(this.pointInfo.point.id)
                    this.newAmenity.shape = this.pointInfo.point
                }else if (val.type === 'edit') {
                    console.log('editt', JSON.parse(JSON.stringify(this.$store.state.siteplan.editingAmenity)))
                    let obj = JSON.parse(JSON.stringify(this.$store.state.siteplan.editingAmenity))
                    this.newAmenity = obj
                }
                this.newTime = Date.now() + ''
            },
            deep: true
        }
    },
    computed: {
        dialog() {
            return this.$store.state.siteplan.amenityDrawer
        },
        pointInfo() {
            return this.$store.state.siteplan.newAmenityInfo
        },
        instance(){
          return this.$store.state.instance
        },
        appData(){
          return this.$store.state.siteplan.appData
        },
        siteplanLots() {
            return Object.values(this.$store.state.siteplan.lots)
        },
    },
    methods: {
        req:msg=>({required:true,message:msg}),
        deleteAmenity() {
            let self = this
            this.$confirm({
            title: "Delete",
            content: h => <div>Do you want to delete this Community Amenity Lot. Deleting will permanently remove the Community Amenity Lot.</div>,
            okText: 'Delete',
            okType: 'danger',
            cancelText: 'Cancel',
            centered: true,
            onOk() {
                self.confirmDelete()
            },
            onCancel() {
                console.log('Cancel')
            }
            })
        },
        confirmDelete () {
            console.log('confirm delete')
            this.$api.delete(`/lots/${this.instance.id}/${this.appData.id}/${this.newAmenity.id}`)
                .then( ({data}) => this.onClose({type:'delete', data})).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
        },
        submit() {
            if(this.dialog.type === 'add') return this.createAmenity()
            return this.updateAmenity()
        },
        createAmenity() {
            console.log('create amenity')
            this.$refs.newAmenity.validate(valid => {
                if (valid) {
                this.loading = true
                let sendObj = JSON.parse(JSON.stringify(this.newAmenity))
                sendObj.spots = this.pointInfo.settings.spots
                console.log('senddd', sendObj)
                if (sendObj.siteplan.id && sendObj.siteplan.id != '') sendObj.siteplan = sendObj.siteplan.id
                this.$api.post(`/lots/${this.instance.id}/${sendObj.siteplan}`, sendObj).then( ({data}) => {
                    console.log('data', data)
                    this.loading = false
                this.onClose({type:'add',data})
                }).catch(err => {
					this.loading = false
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				}).finally(() => {
					this.loading = false
				})
                } else {
                console.log('error submit!!');
                return false;
                }
            });


        },
        updateAmenity() {
            console.log('update amenity')
            this.$refs.newAmenity.validate(valid => {
                if (valid) {
                    this.loading = true
                let sendObj = JSON.parse(JSON.stringify(this.newAmenity))
                console.log('sendobj',sendObj)
                if (sendObj.siteplan.id && sendObj.siteplan.id != '') sendObj.siteplan = sendObj.siteplan.id
                this.$api.put(`/lots/${this.instance.id}/${sendObj.siteplan}/${sendObj.id}`, sendObj).then( ({data}) => {
                    console.log('daata', data)
                    this.loading = false
                    this.onClose({type:'edit',data})
                }).catch(err => {
					this.loading = false
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
                } else {
                console.log('error submit!!');
                return false;
                }
            });

        },
        selectedThumbnail(item) {
            this.newAmenity.thumbnail = item
            console.log('iteemmmmm', item)
        },
        selectedImg(item) {
            this.newAmenity.image = item
        },
        onClose({type='',data={}}) {
            console.log('closeee', type, data)
            // if (type == 'click') {
            //     this.$notification['error']({
            //         message: `YOUR CHANGES ARE UNSAVED`,
            //         description:
            //         `Please click ${this.dialog.type == 'add' ? 'CREATE' : 'UPDATE'} or CANCEL to ${this.dialog.type == 'add' ? 'create or cancel this lot.' : 'update your changes or cancel.'}`,
            //     });
            // } else {
                if (this.dialog.type === 'add' && (!type || type === 'click')){
                    let {settings,self,point} = this.pointInfo

                    let index = settings.spots.findIndex(x => x.id === point.id)
                    console.log('DELEETINGGGGG', index)
                    settings.spots.splice(index,1)
                    self.addAction();
                    self.redraw();
                }
                if (type === 'delete') console.log('DELETING', data.id)
                this.$store.dispatch('CLOSE_AMENITY',{type,data})
                $('[data-wcp-editor-toolbar-button-name=select]').click()
                this.$refs.newAmenity.resetFields();
                this.$store.commit('CLOSE_AMENITY', data)
            // }
        },
    }
}
</script>

<style>

</style>
